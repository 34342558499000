import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssessmentCreateRequest } from '../mm-program/assessment/assessment.model';
import { ProductRequest, ProductRequestResponse, RequestUpdateRequest } from './request.model';

@Injectable()
export class RequestsService {
  private readonly url = `requests`;
  constructor(private highlanderService: HighlanderService) {}

  getAll(isActive?: boolean): Observable<ProductRequest[]> {
    return this.highlanderService.get<ProductRequestResponse[]>(this.url, { params: isActive !== undefined ? { isActive } : {} });
  }

  get(id: string): Observable<ProductRequest> {
    return this.highlanderService.get<ProductRequestResponse>(`${this.url}/${id}`);
  }

  add(data: AssessmentCreateRequest): Observable<ProductRequest> {
    return this.highlanderService.post<AssessmentCreateRequest, ProductRequestResponse>(this.url, data);
  }

  update(id: string, request: RequestUpdateRequest): Observable<ProductRequest> {
    return this.highlanderService.put<RequestUpdateRequest, ProductRequestResponse>(`${this.url}/${id}`, request);
  }
}
