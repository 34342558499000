import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'take',
    pure: true,
    standalone: false
})
export class TakePipe implements PipeTransform {
  transform<T>(value: T[], ...args: number[]): T[] {
    const itemsToShow = args && args[0]

    if (typeof itemsToShow !== 'number') {
      return value
    }

    if (itemsToShow === 0) {
      return []
    }

    const items: T[] = value || []

    return items.filter((i, idx) => idx < itemsToShow)
  }
}
