import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import {
  ICustomer,
  assessmentsListActions,
  assessmentsListSelectors,
  customersActions,
  customersSelectors,
  identitySelectors,
  requestsActions,
  requestsSelectors,
} from '@appState';
import { Store, select } from '@ngrx/store';
import { CuiButtonsModule, CuiCardModule, CuiSearchSelectComponent, SearchSelectItem, SubscribingBase } from 'cui-components';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import { CustomerForm, CustomerFormBaseFormBuilder } from 'src/app/customers/components/customer-form-base/customer-form-base.builder';
import { CustomerFormBaseComponent } from 'src/app/customers/components/customer-form-base/customer-form-base.component';
import { AddAssessmentForm, AddAssessmentModalFormBuilder } from './add-assessment-modal.builder';

@Component({
  imports: [
    CommonModule,
    CustomerFormBaseComponent,
    ReactiveFormsModule,
    FormsModule,
    CuiSearchSelectComponent,
    CuiCardModule,
    CuiButtonsModule,
    MatButtonModule,
  ],
  providers: [AddAssessmentModalFormBuilder, CustomerFormBaseFormBuilder],
  selector: 'ocf-add-assessment-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './add-assessment-modal.component.html',
  styles: [
    `
      cui-search-select {
        background-color: var(--cui-color-card-bg);
      }
    `,
  ],
})
export class NewAssessmentModalComponent extends SubscribingBase implements OnInit {
  isLoading$: Observable<boolean> = combineLatest({
    customerListIsLoading: this.store.pipe(select(customersSelectors.selectIsLoading)),
    assessmentIsLoading: this.store.pipe(select(assessmentsListSelectors.selectIsLoading)),
    customerCreationIsLoading: this.store.pipe(select(assessmentsListSelectors.selectCustomerCreationIsLoading)),
    requestLoading: this.store.pipe(select(requestsSelectors.selectIsLoading)),
  }).pipe(
    tap(({ assessmentIsLoading, requestLoading }) => {
      if (!assessmentIsLoading && !requestLoading && this.closeDialog) {
        this.dialogRef.close();
      }
      this.closeDialog = assessmentIsLoading || requestLoading;
    }),
    map(
      ({ customerListIsLoading, assessmentIsLoading, customerCreationIsLoading, requestLoading }) =>
        customerListIsLoading || assessmentIsLoading || customerCreationIsLoading || requestLoading,
    ),
  );

  customerChoices$: Observable<SearchSelectItem[]> = combineLatest({
    customers: this.store.pipe(select(customersSelectors.selectCustomers)),
    canCreateCustomer: this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.MM.Assessments.Customers.Create']))),
  }).pipe(map(({ customers, canCreateCustomer }) => this.populateCustomerChoices(customers, canCreateCustomer)));

  form: FormGroup<AddAssessmentForm> = this.formBuilder.build();
  customerForm: FormGroup<CustomerForm> = this.customerFormBuilder.build();
  showCustomerForm = false;

  currentUrl: string;

  private closeDialog = false;

  constructor(
    private readonly store: Store<AppState>,
    private readonly formBuilder: AddAssessmentModalFormBuilder,
    private readonly customerFormBuilder: CustomerFormBaseFormBuilder,
    private readonly dialogRef: DialogRef,
    private readonly router: Router,
  ) {
    super();
    this.currentUrl = this.router.url;
  }

  ngOnInit(): void {
    this.store.dispatch(customersActions.getCustomersList());

    this.subscribe(this.form.valueChanges, change => {
      this.showCustomerForm = change.customerId === 'new-customer';
    });
  }

  onSave(form: FormGroup<AddAssessmentForm>, customerForm: FormGroup<CustomerForm>): void {
    const { customerId } = form.getRawValue();
    const { name, street, city, postCode, countryId, stateId } = customerForm.getRawValue();

    const customerData = {
      customer: {
        name,
        address: {
          street,
          city,
          postCode,
          country: countryId,
          state: stateId && stateId !== '' ? stateId : null,
        },
      },
    };

    if (!this.showCustomerForm) {
      const action = this.currentUrl.includes('requests')
        ? requestsActions.addMMRequest({ assessment: { customerId } })
        : assessmentsListActions.addAssessment({ assessment: { customerId } });
      this.store.dispatch(action);
    } else {
      const action = this.currentUrl.includes('requests')
        ? requestsActions.addCustomer(customerData)
        : assessmentsListActions.addCustomer(customerData);
      this.store.dispatch(action);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  populateCustomerChoices(customers: ICustomer[], canCreateCustomer: boolean): SearchSelectItem[] {
    const choices: SearchSelectItem[] = [];

    if (canCreateCustomer) {
      choices.push({
        value: 'new-customer',
        name: 'Create new customer',
      });
    }

    choices.push(
      ...customers
        .map((customer): SearchSelectItem => ({ value: customer.id, name: customer.name }))
        .sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())),
    );

    return choices;
  }
}
