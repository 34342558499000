import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { HttpErrorResponse } from '@angular/common/http';
import { AssessmentCreateRequest, AssessmentUpdateRequest, CustomerCreateRequest, IAssessment, ICustomer } from '@appState';
import { createAction, props } from '@ngrx/store';
import { ProductRequest, RequestsToggleOptions, RequestUpdateRequest } from './request.model';
import { State } from './requests.reducer';

const NAMESPACE = '[MM Requests]';

export const getAllMMRequests = createAction(`${NAMESPACE} GetAllMMRequests`, props<{ toggle: RequestsToggleOptions }>());
export const getAllMMRequestsComplete = createAction(`${NAMESPACE} GetAllMMRequestsComplete`, props<{ requests: ProductRequest[] }>());
export const getAllMMRequestsError = createAction(`${NAMESPACE} GetAllMMRequestsError`, props<{ err: HttpErrorResponse }>());

export const selectMMRequest = createAction(`${NAMESPACE} SelectMMRequest`, props<{ id: string }>());
export const selectMMRequestComplete = createAction(`${NAMESPACE} SelectMMRequestComplete`, props<{ request: ProductRequest }>());
export const selectMMRequestError = createAction(`${NAMESPACE} SelectMMRequestError`, props<{ err: HttpErrorResponse }>());

export const selectMMRequestAssessment = createAction(`${NAMESPACE} SelectMMRequestAssessment`, props<{ id: string }>());
export const selectMMRequestAssessmentComplete = createAction(
  `${NAMESPACE} SelectMMRequestAssessmentComplete`,
  props<{ assessment: IAssessment }>(),
);
export const selectMMRequestAssessmentError = createAction(
  `${NAMESPACE} SelectMMRequestAssessmentError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const addMMRequest = createAction(`${NAMESPACE} AddMMRequest`, props<{ assessment: AssessmentCreateRequest }>());
export const addMMRequestComplete = createAction(`${NAMESPACE} AddMMRequestComplete`, props<{ request: ProductRequest }>());
export const addMMRequestError = createAction(`${NAMESPACE} AddMMRequestError`, props<{ err: HttpErrorResponse }>());

export const addCustomer = createAction(`${NAMESPACE} AddCustomer`, props<{ customer: CustomerCreateRequest }>());
export const addCustomerComplete = createAction(`${NAMESPACE} AddCustomerComplete`, props<{ customer: ICustomer }>());
export const addCustomerError = createAction(`${NAMESPACE} AddCustomerError`, props<{ error: HighlanderCoreErrorResponse }>());

export const updateMMRequest = createAction(`${NAMESPACE} UpdateMMRequest`, props<{ id: string; request: RequestUpdateRequest }>());
export const updateMMRequestComplete = createAction(`${NAMESPACE} UpdateMMRequestComplete`, props<{ request: ProductRequest }>());
export const updateMMRequestError = createAction(`${NAMESPACE} UpdateMMRequestError`, props<{ err: HttpErrorResponse }>());

export const updateMMRequestAssessment = createAction(
  `${NAMESPACE} UpdateMMRequestAssessment`,
  props<{ id: string; assessment: AssessmentUpdateRequest; isDraft: boolean }>(),
);
export const updateMMRequestAssessmentComplete = createAction(
  `${NAMESPACE} UpdateMMRequestAssessmentComplete`,
  props<{ assessment: IAssessment; isDraft: boolean }>(),
);
export const updateMMRequestAssessmentError = createAction(
  `${NAMESPACE} UpdateMMRequestAssessmentError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const submitMMRequestAssessment = createAction(`${NAMESPACE} SubmitMMRequestAssessment`);
export const submitMMRequestAssessmentComplete = createAction(
  `${NAMESPACE} SubmitMMRequestAssessmentComplete`,
  props<{ assessment: IAssessment }>(),
);
export const submitMMRequestAssessmentError = createAction(
  `${NAMESPACE} SubmitMMRequestAssessmentError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const deleteSelectedMMRequest = createAction(`${NAMESPACE} DeleteSelectedMMRequest`);
export const deleteSelectedMMRequestComplete = createAction(`${NAMESPACE} DeleteSelectedMMRequestComplete`);
export const deleteSelectedMMRequestError = createAction(`${NAMESPACE} DeleteSelectedMMRequestError`, props<{ err: HttpErrorResponse }>());

export const goToMMRequestActions = createAction(`${NAMESPACE} GoToMMRequestActions`, props<{ id: string }>());
export const goToListPage = createAction(`${NAMESPACE} GoToListPage`);

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
export const resetAssessmentSaveCompleted = createAction(`${NAMESPACE} ResetAssessmentSaveCompleted`);

export const getProductDescription = createAction(`${NAMESPACE} GetProductDescription`, props<{ id: string }>());
export const getProductDescriptionComplete = createAction(`${NAMESPACE} GetProductDescriptionComplete`, props<{ description: string }>());
export const getProductDescriptionError = createAction(`${NAMESPACE} GetProductDescriptionError`, props<{ error: HttpErrorResponse }>());
