import * as accountActions from './account/account.actions';
import { AccountEffects } from './account/account.effects';
import * as accountSelectors from './account/account.selectors';
import { AccountService } from './account/account.service';

import * as appStore from './app.store';

import { ActivePageService } from './active-pages/active-page.service';

import * as backendActions from '@1clickfactory/backend/backend.actions';
import { BackendEffects } from '@1clickfactory/backend/backend.effects';

import { CartItemsBulkService } from './cart/cart-items/cart-items-bulk.service';
import * as cartItemsActions from './cart/cart-items/cart-items.actions';
import { CartItemsEffects } from './cart/cart-items/cart-items.effects';
import * as cartItemsSelectors from './cart/cart-items/cart-items.selectors';
import { CartItemsService } from './cart/cart-items/cart-items.service';

import * as cartPaymentActions from './cart/cart-payment/cart-payment.actions';
import { CartPaymentEffects } from './cart/cart-payment/cart-payment.effects';
import { CartPaymentService } from './cart/cart-payment/cart-payment.service';

import * as countryActions from './country/actions';
import { CountryService } from './country/country.service';
import { CountryEffects } from './country/effects';
import * as countrySelectors from './country/selectors';

import * as creditActions from './credit/credit.actions';
import { CreditEffects } from './credit/credit.effects';
import * as creditSelectors from './credit/credit.selectors';
import { CreditService } from './credit/credit.service';

import * as identityActions from './identity/identity.actions';
import { IdentityEffects } from './identity/identity.effects';
import * as identitySelectors from './identity/identity.selectors';

import { NotificationsEffects } from '@1clickfactory/notifications/notifications.effects';

import { PageEffects } from './active-pages/active-page.effects';
import * as pageSelectors from './active-pages/active-page.selectors';

import * as partnerActions from './partner/partner.actions';
import { PartnerEffects } from './partner/partner.effects';
import * as partnerSelectors from './partner/partner.selectors';
import { PartnerService } from './partner/partner.service';

import * as paymentInformationActions from './cart/payment-information/payment-information.action';
import { PaymentInformationEffects } from './cart/payment-information/payment-information.effects';
import * as paymentInformationSelectors from './cart/payment-information/payment-information.selectors';
import { UserCompanyService } from './cart/payment-information/user-company.service';

import * as paymentTokenActions from './cart/payment-token/payment-token.actions';
import { PaymentTokenEffects } from './cart/payment-token/payment-token.effects';
import { PaymentTokenService } from './cart/payment-token/payment-token.service';

import { RouterEffects } from './router-state/router-state.effects';
import * as routerSelectors from './router-state/router-state.selectors';
import * as routerActions from './router-state/router.actions';

import * as stateActions from './state/actions';
import { StateEffects } from './state/effects';
import * as stateSelectors from './state/selectors';
import { StateService } from './state/state.service';

import * as userApprovalActions from './user/user-approval/user-approval.actions';
import { UserApprovalEffects } from './user/user-approval/user-approval.effects';
import { UserApprovalService } from './user/user-approval/user-approval.service';

import * as userValidationActions from './user/user-validation/user-validation.actions';
import * as userValidationSelectors from './user/user-validation/user-validation.selectors';

import { ImpersonationCookiesService } from './impersonation/impersonation-cookies.service';
import * as impersonationActions from './impersonation/impersonation.actions';
import { ImpersonationEffects } from './impersonation/impersonation.effects';
import * as impersonationSelectors from './impersonation/impersonation.selectors';
import { ImpersonationService } from './impersonation/impersonation.service';

import * as documentsActions from './documents/documents-list/documents-list.actions';
import { DocumentsListEffects } from './documents/documents-list/documents-list.effects';
import * as documentsSelectors from './documents/documents-list/documents-list.selectors';
import { DocumentsService } from './documents/documents-list/documents-list.service';

import * as supportActions from './support/support.actions';
import { SupportEffects } from './support/support.effects';
import * as supportSelectors from './support/support.selectors';
import { SupportService } from './support/support.service';

import * as commonDataActions from './common-data/common-data.action';
import { CommonDataEffects } from './common-data/common-data.effects';
import * as commonDataSelectors from './common-data/common-data.selectors';
import { CommonDataService } from './common-data/common-data.service';

import * as rolesActions from './authorization/roles/roles.actions';
import { RolesEffects } from './authorization/roles/roles.effects';
import * as rolesSelectors from './authorization/roles/roles.selectors';

import * as roleGroupsActions from './authorization/role-groups/role-groups.actions';
import { RoleGroupsEffects } from './authorization/role-groups/role-groups.effects';
import * as roleGroupsSelectors from './authorization/role-groups/role-groups.selector';

import { RoleGroupsService } from './authorization/role-groups/role-groups.service';
import { RolesService } from './authorization/roles/roles.service';

import * as userAnonymousActions from './user/user-anonymous/user-anonymous.actions';
import { UserAnonymousEffects } from './user/user-anonymous/user-anonymous.effects';
import * as userAnonymousSelectors from './user/user-anonymous/user-anonymous.selectors';
import { UserAnonymousService } from './user/user-anonymous/user-anonymous.service';

import { UserValidationService } from './user/user-validation/user-validation.service';

import * as partnersActions from './partners/partners.actions';
import { PartnersEffects } from './partners/partners.effects';
import * as partnersSelectors from './partners/partners.selectors';
import { PartnersService } from './partners/partners.service';

import * as notificationSubsActions from './notification-subscriptions/notification-subs.actions';
import { NotificationSubscriptionsEffects } from './notification-subscriptions/notification-subs.effects';
import * as notificationSubsSelectors from './notification-subscriptions/notification-subs.selectors';
import { UserNotificationSubsService } from './notification-subscriptions/notification-subs.service';

import { UserInfoService } from './user/users/user-info.service';
import * as usersActions from './user/users/users.actions';
import { UsersEffects } from './user/users/users.effects';
import * as usersSelectors from './user/users/users.selectors';
import { UsersService } from './user/users/users.service';

import * as customersActions from './customers/customers.actions';
import { CustomersEffects } from './customers/customers.effects';
import * as customersSelectors from './customers/customers.selectors';
import { CustomersService } from './customers/customers.service';

import * as assessmentsListActions from './mm-program/assessment/assessments-list/assessments-list.actions';
import { AssessmentsListEffects } from './mm-program/assessment/assessments-list/assessments-list.effects';
import * as assessmentsListSelectors from './mm-program/assessment/assessments-list/assessments-list.selectors';
import { AssessmentsService } from './mm-program/assessment/assessments.service';

import * as announcementsActions from './announcements/announcements.actions';
import { AnnouncementsEffects } from './announcements/announcements.effects';
import * as announcementsSelectors from './announcements/announcements.selectors';
import { AnnouncementsService } from './announcements/announcements.service';

import * as agreementsActions from './agreements/agreements.actions';
import { AgreementsEffects } from './agreements/agreements.effects';
import * as agreementsSelectors from './agreements/agreements.selectors';
import { AgreementsService } from './agreements/agreements.service';
import { IdentityService } from './identity/identity.service';

import * as newsArticlesActions from './news-articles/news-articles.actions';
import { NewsArticlesEffects } from './news-articles/news-articles.effects';
import * as newsArticlesSelectors from './news-articles/news-articles.selectors';
import { NewsArticlesService } from './news-articles/news-articles.service';

import { ProductsService } from './products/products.service';

import * as environmentsActions from './dynamics-arc/environments/environments.actions';
import { EnvironmentsEffects } from './dynamics-arc/environments/environments.effects';
import * as environmentsSelectors from './dynamics-arc/environments/environments.selectors';
import { EnvironmentsService } from './dynamics-arc/environments/environments.service';

import * as mmUtilSelectors from './/mm-program/mm-util/mm-util.selectors';
import * as mmUtilActions from './mm-program/mm-util/mm-util.actions';
import { MMUtilEffects } from './mm-program/mm-util/mm-util.effects';
import { MMUtilService } from './mm-program/mm-util/mm-util.service';

import * as customerReportActions from './mm-program-report/customer-report.actions';
import { CustomerReportEffects } from './mm-program-report/customer-report.effects';
import * as customerReportSelectors from './mm-program-report/customer-report.selectors';
import { CustomerReportService } from './mm-program-report/customer-report.service';

import * as dashboardActions from './dashboard/dashboard.actions';
import { DashboardEffects } from './dashboard/dashboard.effects';
import * as dashboardSelectors from './dashboard/dashboard.selectors';
import { DashboardService } from './dashboard/dashboard.service';

import * as requestsActions from './requests/requests.actions';
import { RequestsEffects } from './requests/requests.effects';
import * as requestsSelectors from './requests/requests.selectors';
import { RequestsService } from './requests/requests.service';

export * from './dynamics-arc/apps/apps.store';

export * from '@1clickfactory/backend/backend.models';
export * from './account/account.model';
export * from './agreements/agreement.model';
export * from './authorization/permissions/permissions.model';
export * from './authorization/roles/roles.model';
export * from './cart/cart-items/cart-item.model';
export * from './cart/cart-payment/cart-payment.model';
export * from './cart/payment-information/cart-billing-information.model';
export * from './cart/payment-information/payment-method.model';
export * from './common-data/common-data.model';
export * from './credit/credit.model';
export * from './customers/customer.model';
export * from './documents/document.model';
export * from './dynamics-arc/apps/app.model';
export * from './dynamics-arc/environments/environment.model';
export * from './identity/guards/agreements.guard';
export * from './identity/identity.models';
export * from './mm-program/assessment/assessment.model';
export * from './mm-program/mm-util/mm-util.model';
export * from './partner/partner.model';
export * from './partners/partners.model';
export * from './products/products.model';
export * from './route-params.constants';
export * from './router-state/router-state.model';
export * from './support/support.model';
export * from './user/user-approval/user-approval.model';
export * from './user/users/user.model';

export { AccountGroup } from './account/account-group.model';
export { Account } from './account/account.model';
export { AgreementsGuard } from './identity/guards/agreements.guard';
export { AuthGuard } from './identity/guards/auth.guard';
export { PermissionsGuard } from './identity/guards/permissions.guard';
export { PublicAgreementsGuard } from './identity/guards/public-agreements.guard';

export {
  accountActions,
  accountSelectors,
  agreementsActions,
  agreementsSelectors,
  announcementsActions,
  announcementsSelectors,
  appStore,
  assessmentsListActions,
  assessmentsListSelectors,
  backendActions,
  cartItemsActions,
  cartItemsSelectors,
  cartPaymentActions,
  commonDataActions,
  commonDataSelectors,
  countryActions,
  countrySelectors,
  creditActions,
  creditSelectors,
  customerReportActions,
  customerReportSelectors,
  customersActions,
  customersSelectors,
  dashboardActions,
  dashboardSelectors,
  documentsActions,
  documentsSelectors,
  environmentsActions,
  environmentsSelectors,
  identityActions,
  identitySelectors,
  impersonationActions,
  impersonationSelectors,
  mmUtilActions,
  mmUtilSelectors,
  newsArticlesActions,
  newsArticlesSelectors,
  notificationSubsActions,
  notificationSubsSelectors,
  pageSelectors,
  partnerActions,
  partnersActions,
  partnerSelectors,
  partnersSelectors,
  paymentInformationActions,
  paymentInformationSelectors,
  paymentTokenActions,
  requestsActions,
  requestsSelectors,
  roleGroupsActions,
  roleGroupsSelectors,
  rolesActions,
  rolesSelectors,
  routerActions,
  routerSelectors,
  stateActions,
  stateSelectors,
  supportActions,
  supportSelectors,
  userAnonymousActions,
  userAnonymousSelectors,
  userApprovalActions,
  usersActions,
  usersSelectors,
  userValidationActions,
  userValidationSelectors,
};

export const ROOT_STATE_SERVICES = [
  AccountService,
  ActivePageService,
  CartItemsBulkService,
  CartItemsService,
  CartPaymentService,
  CreditService,
  CountryService,
  PartnerService,
  PaymentTokenService,
  StateService,
  UserApprovalService,
  UserCompanyService,
  ImpersonationService,
  IdentityService,
  ImpersonationCookiesService,
  DocumentsService,
  CommonDataService,
  RoleGroupsService,
  RolesService,
  RoleGroupsService,
  UserAnonymousService,
  UserValidationService,
  PartnersService,
  UsersService,
  UserInfoService,
  CustomersService,
  AnnouncementsService,
  AgreementsService,
  SupportService,
  AssessmentsService,
  NewsArticlesService,
  ProductsService,
  EnvironmentsService,
  MMUtilService,
  UserNotificationSubsService,
  CustomerReportService,
  DashboardService,
  RequestsService,
];

export const ROOT_STATE_EFFECTS = [
  AccountEffects,
  BackendEffects,
  CountryEffects,
  CreditEffects,
  CartPaymentEffects,
  CartItemsEffects,
  IdentityEffects,
  NotificationsEffects,
  PageEffects,
  PartnerEffects,
  PaymentTokenEffects,
  PaymentInformationEffects,
  RouterEffects,
  StateEffects,
  UserApprovalEffects,
  DocumentsListEffects,
  ImpersonationEffects,
  CommonDataEffects,
  RolesEffects,
  RoleGroupsEffects,
  UserAnonymousEffects,
  PartnersEffects,
  UsersEffects,
  AnnouncementsEffects,
  AgreementsEffects,
  SupportEffects,
  AssessmentsListEffects,
  NewsArticlesEffects,
  EnvironmentsEffects,
  MMUtilEffects,
  NotificationSubscriptionsEffects,
  CustomerReportEffects,
  DashboardEffects,
  RequestsEffects,
  CustomersEffects,
];
