import { ChangeDetectionStrategy, Component, Input, Optional, TemplateRef, ViewChild } from '@angular/core';
import { ControlContainer, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ocf-wizard-step',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-template>
      <div class="wizard-step-content">
        <div class="panel-text" [innerHTML]="topText | parseHtmlDeprecated"></div>
        <ng-content></ng-content>
        <div class="panel-text" [innerHTML]="bottomText | parseHtmlDeprecated"></div>
      </div>
    </ng-template>
  `,
  styleUrls: ['./ocf-wizard-step.component.css'],
  standalone: false,
})
export class OcfWizardStepComponent {
  @Input() title: string = '';
  @Input() panelTexts: { [key: string]: string } = {};
  @Input() step: number = 0;
  @ViewChild(TemplateRef, { static: true }) stepContent!: TemplateRef<any>;

  constructor(@Optional() private cont: ControlContainer) {}

  get formGroup(): UntypedFormGroup {
    return this.cont?.control as UntypedFormGroup;
  }

  get isTouchedAndCompleted(): boolean {
    return this.formGroup?.touched && this.formGroup?.valid;
  }

  get isTouchedAndInvalid(): boolean {
    return this.formGroup?.touched && this.formGroup.invalid;
  }

  get topText(): string {
    if (!this.arePanelTextsAvailable) {
      return '';
    }
    return this.panelTexts[this.getPanelTextKey('top')] || '';
  }

  get bottomText(): string {
    if (!this.arePanelTextsAvailable) {
      return '';
    }
    return this.panelTexts[this.getPanelTextKey('top')] || '';
  }

  get arePanelTextsAvailable(): boolean {
    return !!this.panelTexts && typeof this.step === 'number';
  }

  getPanelTextKey(position: 'top' | 'bottom'): string {
    return `__${this.step}__${position}`;
  }
}
