import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CommentRequest,
  NewsArticle,
  NewsArticleCommentListItem,
  NewsArticleFilterParams,
  NewsArticleListItem,
  NewsArticleListItemResponse,
  NewsArticleResponse,
  ReactRequest,
} from './news-article.model';

@Injectable()
export class NewsArticlesService {
  private readonly url = 'news-articles';
  constructor(private highlanderService: HighlanderService) {}

  getAll(filters: NewsArticleFilterParams): Observable<NewsArticleListItem[]> {
    return this.highlanderService.get<NewsArticleListItemResponse[]>(this.url, { params: filters });
  }

  getOne(articleId: string, communityId: string): Observable<NewsArticle> {
    return this.highlanderService.get<NewsArticleResponse>(`${this.url}/${articleId}`, { params: { communityId } });
  }

  getFile(articleId: string, fileId: string): Observable<any> {
    return this.highlanderService.download<any>(`${this.url}/${articleId}/files/${fileId}`);
  }

  reactOnArticle(articleId: string, req: ReactRequest): Observable<NewsArticle> {
    return this.highlanderService.post(`${this.url}/${articleId}/react`, req);
  }

  getAllComments(articleId: string, communityId: string): Observable<NewsArticleCommentListItem[]> {
    return this.highlanderService.get(`${this.url}/${articleId}/comments`, {
      params: { includeSubComments: true, includeUserReactions: true, communityId },
    });
  }

  createComment(articleId: string, req: CommentRequest): Observable<NewsArticleCommentListItem> {
    return this.highlanderService.post(`${this.url}/${articleId}/comments`, req);
  }

  reactOnComment(articleId: string, commentId: string, req: ReactRequest): Observable<NewsArticleCommentListItem> {
    return this.highlanderService.post(`${this.url}/${articleId}/comments/${commentId}/react`, req);
  }
}
