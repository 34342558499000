import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { StateDictionary, createStateResetHandler, getAll, getOne } from '../../helpers';
import { App, AppsQueryParams } from './app.model';
import { AppsService } from './apps.service';

type AppsState = {
  apps: App[];
  isLoading: boolean;
  selectedApp: App | null;
  selectedAppLoading: boolean;
};

const initialState: AppsState = {
  apps: [],
  isLoading: false,
  selectedApp: null,
  selectedAppLoading: false,
};

const appsDictionary: StateDictionary<App> = {
  entityList: 'apps',
  listLoading: 'isLoading',
  selectedEntity: 'selectedApp',
  selectedEntityLoading: 'selectedAppLoading',
  entityId: 'id',
  sortFn: (a, b) => a.name.localeCompare(b.name),
  // unused
  createLoading: '',
};

export const AppsStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withDevtools('apps-store'),
  withComputed(state => ({
    selectedAppId: computed(() => state.selectedApp()?.id || null),
  })),
  withMethods((store, appsService = inject(AppsService), router = inject(Router)) => {
    createStateResetHandler<AppsState>('/apps')(store, initialState);

    const repositoryMethods = {
      getAll: getAll<App, AppsQueryParams>({
        store,
        service: appsService,
        dictionary: appsDictionary,
      }),

      getOne: getOne<App>({ store, service: appsService, dictionary: appsDictionary }),
    };

    const navigationActions = {
      goToAppActions: (id: string, environmentId?: string): void => {
        repositoryMethods.getOne(id);
        environmentId ? router.navigate(['environments', environmentId, 'apps', id]) : router.navigate(['apps', id]);
      },

      goToListPage: (environmentId?: string | null): void => {
        patchState(store, {
          selectedApp: null,
        });
        environmentId ? router.navigate(['environments', environmentId, 'apps']) : router.navigate(['apps']);
      },

      resetState: (state: Partial<AppsState>) => patchState(store, state),
    };

    return {
      ...repositoryMethods,
      ...navigationActions,
    };
  }),
);

export type AppsStore = typeof AppsStore extends infer T ? (T extends new (...args: any[]) => infer R ? R : T) : never;
