import { ActivatedRoute, Router } from '@angular/router';

export function parseToNullOrNumber(value: number | string | null): number | null {
  if (value === null) return null;

  return isNaN(parseInt(value.toString())) ? null : Number(value);
}

export function convertUTCDateToLocalDate(date: string | number | Date): Date {
  let utcDate: Date;

  if (typeof date === 'string' || typeof date === 'number') {
    utcDate = new Date(date);
  } else {
    utcDate = date;
  }

  return new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60 * 1000);
}

/** Use in getQuickFilterText
 * @returns '' to exclude column from search
 */
export function excludeColumnFromSearch(): string {
  return '';
}

export const isGUID = (val: string): boolean => {
  const guidRegxp = /^[0-9a-fA-F]{8}(?:-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}$/;
  return guidRegxp.test(val);
};

export const routerGoBack = (router: Router, activatedRoute: ActivatedRoute): void => {
  // Start with the current activated route, transform it into url string
  const currentUrl = activatedRoute.pathFromRoot
    .map(segment => segment.snapshot.url.map(url => url.path).join('/'))
    .filter(Boolean)
    .join('/');

  // Split the URL into segments
  const urlSegments = currentUrl.split('/');

  if (urlSegments.length > 1) {
    // Remove the last segment to navigate one level up
    const parentUrl = urlSegments.slice(0, -1).join('/');
    router.navigateByUrl(`/${parentUrl}`);
  } else {
    // If there's no parent URL, navigate to the root
    router.navigate(['/']);
  }
};

export const getFilterFromLocalStorage = (tableId: string): { toggleSearch: string; gridSearch: null | string; id: string } | null => {
  const filterSettings = JSON.parse(localStorage.getItem('TABLE_FILTER_SETTINGS')!);
  return filterSettings?.id === tableId ? filterSettings : null;
};

export const findAndReplace = <T>(array: T[], newItem: T, predicate: (item: T) => boolean): T[] => {
  const newArray: T[] = [];

  for (const item of array) {
    if (predicate(item)) {
      newArray.push(newItem);
    } else {
      newArray.push(item);
    }
  }

  return newArray;
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case 'Failed':
      return 'var(--color-danger)';
    case 'InProgress':
    case 'Queued':
      return 'var(--color-accent)';
    case 'Completed':
      return 'var(--color-success)';
    default:
      return 'unset';
  }
};
