/**
 * Example output: "2019-10-24T00:00:00.000Z"
 */
export function formatToISODate(date?: string): string | null {
  if (!date) {
    return null;
  }
  const dateObj = new Date(date);
  // by subtracting the timezone from the date in milliseconds, we get UTC midnight
  return new Date(dateObj.valueOf() - dateObj.getTimezoneOffset() * 60000).toISOString();
}

export function getDateWithZeroTime(date: string | Date): Date {
  return new Date(new Date(date).setHours(0, 0, 0, 0));
}

export function getLocalTimeFromISOString(isoString: string): string {
  const dateTimeObj = new Date(isoString);

  return dateTimeObj.toLocaleTimeString(Intl.DateTimeFormat().resolvedOptions().locale, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
}
