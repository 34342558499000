import { CountryRepository } from '@1clickfactory/forms2/ocf-country-state/ocf-countries.repository';
import { OcfStatesRepository } from '@1clickfactory/forms2/ocf-country-state/ocf-states.repository';
import { CartBillingInformationViewModel, CartItemType, PaymentTypes } from '@appState';
import { createSelector } from '@ngrx/store';
import { getRootState } from '../../app.store';
// noinspection ES6PreferShortImport
import { selectRemainingCreditLimit } from '../../credit/credit.selectors';
import * as cartItemsSelectors from '../cart-items/cart-items.selectors';
import { BillingInformation, CartBillingInformation } from './cart-billing-information.model';

/** Taken from `CountryRepository` */
const LITHUANIA_COUNTRY_ID = 133;
const LITHUANIA_VAT = 0.21;
export const PAYMENT_METHOD_FEE = 0.08;

export const selectModuleState = createSelector(getRootState, rootState => rootState.paymentInformation);

export const selectCompanyInformation = createSelector(selectModuleState, state =>
  state.company ? mapCompanyInformationToBilling(state.company) : null,
);

export const selectCartBillingInformation = createSelector(
  selectModuleState,
  selectCompanyInformation,
  (state): CartBillingInformationViewModel => ({
    ...state.cartBillingInformation,
    countryName: findCountry(state.cartBillingInformation.countryId)!,
    stateName: state.cartBillingInformation.stateId ? findState(state.cartBillingInformation.stateId)! : '',
  }),
);

export const selectPaymentMethod = createSelector(selectModuleState, state => state.paymentMethod);
export const selectBraintreeToken = createSelector(selectModuleState, state => state.braintreeToken);
export const selectMaskedCreditCardNumber = createSelector(selectModuleState, state => state.paymentMethod?.maskedCreditCardNumber ?? null);

export const selectVatMultiplier = createSelector(
  selectCartBillingInformation,
  ({ countryId }) => 1 + (countryId === LITHUANIA_COUNTRY_ID ? LITHUANIA_VAT : 0),
);

export const selectPaymentMethodMultiplier = createSelector(
  selectPaymentMethod,
  ({ paymentType }) => 1 + (paymentType === PaymentTypes.Invoice ? PAYMENT_METHOD_FEE : 0),
);

export const selectNonTaxableItems = createSelector(cartItemsSelectors.selectCartItems, cartItems =>
  cartItems.filter(({ itemType }) => itemType === CartItemType.Invoice),
);

export const selectTaxableItems = createSelector(cartItemsSelectors.selectCartItems, cartItems =>
  cartItems.filter(({ itemType }) => itemType !== CartItemType.Invoice),
);

export const selectTaxableItemPrices = createSelector(selectTaxableItems, cartItems => cartItems.map(({ price }) => price));

export const selectCartTotalBeforeFees = createSelector(cartItemsSelectors.selectCartItems, cartItems => {
  const cartTotalBeforeFees = cartItems?.reduce((total, item) => total + (item.quantity ?? 1) * item.price, 0);
  /* Number EPSILON is used to ensure things like 1.005 round correctly  */
  return Math.round((cartTotalBeforeFees + Number.EPSILON) * 100) / 100;
});

export const selectNonTaxableSum = createSelector(selectNonTaxableItems, nonTaxableItems =>
  sumPrices(nonTaxableItems.map(({ price }) => price)),
);

export const selectCartTotalWithVat = createSelector(
  selectNonTaxableSum,
  selectTaxableItemPrices,
  selectVatMultiplier,
  (nonTaxableSum, taxableItemPrices, vatMultiplier) => calculateTotalPrice(nonTaxableSum, taxableItemPrices, vatMultiplier),
);

export const selectIsBankTransferCreditExceeded = createSelector(
  selectNonTaxableSum,
  selectTaxableItemPrices,
  selectVatMultiplier,
  selectRemainingCreditLimit,
  (nonTaxableSum, taxableItemPrices, vatMultiplier, remainingCreditLimit) =>
    calculateTotalPrice(nonTaxableSum, taxableItemPrices, vatMultiplier, 1 + PAYMENT_METHOD_FEE) > remainingCreditLimit!,
);

export const selectCartTotalWithVatAndBankTransferFee = createSelector(
  selectNonTaxableSum,
  selectTaxableItemPrices,
  selectVatMultiplier,
  selectPaymentMethodMultiplier,
  (nonTaxableSum, taxableItemPrices, vatMultiplier, bankTransferFeeMultiplier) =>
    calculateTotalPrice(nonTaxableSum, taxableItemPrices, vatMultiplier, bankTransferFeeMultiplier),
);

const calculateTotalPrice = (
  nonTaxableSum: number,
  taxableItemPrices: number[],
  vatMultiplier: number = 1,
  bankTransferFeeMultiplier: number = 1,
): number =>
  nonTaxableSum +
  sumPrices(
    taxableItemPrices
      // Apply VAT
      .map(price => multiplyDecimal(price, vatMultiplier))
      // Apply bank transfer fee
      .map(price => multiplyDecimal(price, bankTransferFeeMultiplier)),
  );

export const selectCartTotalAfterFees = createSelector(selectCartTotalWithVatAndBankTransferFee, total => total);

const findCountry = (countryId: number): string | undefined => CountryRepository.find(country => country.id === countryId)?.name;

const findState = (stateId: number): string | undefined => OcfStatesRepository.find(state => state.id === stateId)?.name;

const mapCompanyInformationToBilling = (companyInformation: BillingInformation): CartBillingInformation =>
  ({
    address: companyInformation?.BilltoAddress,
    city: companyInformation?.BilltoCity,
    companyName: companyInformation?.BilltoCompanyName,
    countryId: companyInformation?.BilltoCountryId,
    stateId: companyInformation?.BilltoStateId,
    email: companyInformation?.BilltoEmail,
    registrationNo: companyInformation?.BilltoRegistrationNo,
    zipCode: companyInformation?.BilltoZipCode,
  }) as CartBillingInformation;

const multiplyDecimal = (a: number, b: number): number => (a * 100 * (b * 100)) / 10 ** 4;

const sumPrices = (numbers: number[]) => numbers?.reduce((previous, current) => previous + current * 100, 0) / 100 || 0;
