import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'parseHtmlDeprecated',
  standalone: false,
})
export class DeprecatedParseHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(content: string): SafeHtml {
    const text = document.createElement('textarea');
    text.innerHTML = content;
    return this.sanitizer.bypassSecurityTrustHtml(text.value);
  }
}
