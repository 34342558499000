import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';

export const selectModuleState = createSelector(getRootState, rootState => rootState.customers);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectCustomers = createSelector(selectModuleState, state => state.customers);

export const selectIsCreateLoading = createSelector(selectModuleState, state => state.isCreateLoading);

export const selectSelectedCustomer = createSelector(selectModuleState, state => state.selectedCustomer);
export const selectSelectedCustomerId = createSelector(selectModuleState, state => state.selectedCustomer?.id ?? null);
export const selectIsSelectedCustomerLoading = createSelector(selectModuleState, state => state.isSelectedCustomerLoading);

export const selectTenantEnvironmentQuota = createSelector(selectModuleState, state => state.tenantEnvironmentQuotaInfo);
export const selectIsTenantEnvironmentQuotaLoading = createSelector(selectModuleState, state => state.isTenantEnvironmentQuotaLoading);

export const selectAvailableCountries = createSelector(selectModuleState, state => state.availableCountries);
export const selectIsAvailableCountriesLoading = createSelector(selectModuleState, state => state.isAvailableCountriesLoading);
