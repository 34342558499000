import { createReducer, on } from '@ngrx/store';
import { AvailableCountryChoice, ICustomer, TenantEnvironmentQuota } from './customer.model';
import * as fromActions from './customers.actions';

export interface State {
  customers: ICustomer[];
  isLoading: boolean;

  selectedCustomer: ICustomer | null;
  isSelectedCustomerLoading: boolean;

  isCreateLoading: boolean;

  tenantEnvironmentQuotaInfo: TenantEnvironmentQuota | null;
  isTenantEnvironmentQuotaLoading: boolean;

  availableCountries: AvailableCountryChoice[];
  isAvailableCountriesLoading: boolean;
}

export const initialState: State = {
  customers: [],
  isLoading: false,

  selectedCustomer: null,
  isSelectedCustomerLoading: false,

  isCreateLoading: false,

  tenantEnvironmentQuotaInfo: null,
  isTenantEnvironmentQuotaLoading: false,

  availableCountries: [],
  isAvailableCountriesLoading: false,
};

export const customersReducer = createReducer(
  initialState,

  on(fromActions.getCustomersList, state => ({ ...state, isLoading: true })),
  on(fromActions.getCustomersListComplete, (state, { customers }) => ({
    ...state,
    isLoading: false,
    customers,
  })),
  on(fromActions.getCustomersListError, state => ({ ...state, isLoading: false })),

  on(fromActions.selectCustomer, state => ({ ...state, isSelectedCustomerLoading: true })),
  on(fromActions.selectCustomerComplete, (state, { customer }) => ({
    ...state,
    isSelectedCustomerLoading: false,
    selectedCustomer: customer,
  })),
  on(fromActions.selectCustomerError, state => ({ ...state, isSelectedCustomerLoading: false })),

  on(fromActions.addCustomer, state => ({ ...state, isSelectedCustomerLoading: true, isCreateLoading: true })),
  on(fromActions.addCustomerComplete, (state, { customer }) => ({
    ...state,
    isSelectedCustomerLoading: false,
    selectedCustomer: customer,
    isCreateLoading: false,
  })),
  on(fromActions.addCustomerError, state => ({ ...state, isSelectedCustomerLoading: false, isCreateLoading: false })),

  on(fromActions.updateCustomer, state => ({ ...state, isSelectedCustomerLoading: true })),
  on(fromActions.updateCustomerComplete, (state, { customer }) => ({
    ...state,
    isSelectedCustomerLoading: false,
    selectedCustomer: customer,
  })),
  on(fromActions.updateCustomerError, state => ({ ...state, isSelectedCustomerLoading: false })),

  on(fromActions.activateCustomer, state => ({ ...state, isSelectedCustomerLoading: true })),
  on(fromActions.activateCustomerComplete, (state, { customer }) => ({
    ...state,
    isSelectedCustomerLoading: false,
    selectedCustomer: customer,
  })),
  on(fromActions.activateCustomerError, state => ({ ...state, isSelectedCustomerLoading: false })),

  on(fromActions.deactivateCustomer, state => ({ ...state, isSelectedCustomerLoading: true })),
  on(fromActions.deactivateCustomerComplete, (state, { customer }) => ({
    ...state,
    isSelectedCustomerLoading: false,
    selectedCustomer: customer,
  })),
  on(fromActions.deactivateCustomerError, state => ({ ...state, isSelectedCustomerLoading: false })),

  on(fromActions.refreshCustomerEnvironments, state => ({ ...state, isSelectedCustomerLoading: true })),
  on(fromActions.refreshCustomerEnvironmentsComplete, state => ({ ...state, isSelectedCustomerLoading: false })),
  on(fromActions.refreshCustomerEnvironmentsError, state => ({ ...state, isSelectedCustomerLoading: false })),

  on(fromActions.getTenantEnvironmentQuota, state => ({
    ...state,
    isTenantEnvironmentQuotaLoading: true,
  })),
  on(fromActions.getTenantEnvironmentQuotaComplete, (state, { quotaInfo }) => ({
    ...state,
    isTenantEnvironmentQuotaLoading: false,
    tenantEnvironmentQuotaInfo: quotaInfo,
  })),
  on(fromActions.getTenantEnvironmentQuotaError, state => ({
    ...state,
    isTenantEnvironmentQuotaLoading: false,
    tenantEnvironmentQuotaInfo: null,
  })),

  on(fromActions.getAvailableCountries, state => ({
    ...state,
    isAvailableCountriesLoading: true,
  })),
  on(fromActions.getAvailableCountriesComplete, (state, { countries }) => ({
    ...state,
    isAvailableCountriesLoading: false,
    availableCountries: countries,
  })),
  on(fromActions.getAvailableCountriesError, state => ({
    ...state,
    isAvailableCountriesLoading: false,
    availableCountries: [],
  })),

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),
);
