import { CountryStateComponent } from '@1clickfactory/simple-ui';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CuiInputComponent } from 'cui-components';
import { CustomerForm } from './customer-form-base.builder';

@Component({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CuiInputComponent, CountryStateComponent],
  selector: 'ocf-customer-form-base',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './customer-form-base.component.html',
})
export class CustomerFormBaseComponent {
  @Input() isLoading: boolean = false;
  @Input() form!: FormGroup<CustomerForm>;
  @Input() isDetailsView: boolean = false;

  setLoading(value: boolean): void {
    this.isLoading = value;
  }
}
