<form [formGroup]="form">
  <cui-input formControlName="name" label="Name" [required]="true"></cui-input>
  <cui-input formControlName="street" label="Address"></cui-input>
  <cui-input formControlName="city" label="City"></cui-input>
  <cui-input formControlName="postCode" label="Post Code"></cui-input>
  <ocf-country-state (isLoading)="setLoading($event)"></ocf-country-state>
  @if (isDetailsView) {
    <cui-input formControlName="entraTenantId" label="Entra Tenant ID" [copyBtn]="true"></cui-input>
    <cui-input formControlName="applicationActivated" label="Activated for Cloud Hub"></cui-input>
    <cui-input formControlName="cspRelationInactive" label="CSP relation is Active"></cui-input>
  }
</form>
