import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DialogService } from 'cui-components';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationDialogData } from 'src/app/components/dialogs';
import { EnvironmentsService } from '../dynamics-arc/environments/environments.service';
import { ICustomer } from './customer.model';
import * as fromActions from './customers.actions';
import { CustomersService } from './customers.service';

@Injectable()
export class CustomersEffects {
  onGetCustomersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getCustomersList),
      switchMap(() =>
        this.customersService.getAll().pipe(
          map((customers: ICustomer[]) => fromActions.getCustomersListComplete({ customers })),
          catchError(({ error }) => of(fromActions.getCustomersListError({ error }))),
        ),
      ),
    ),
  );

  onGetCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectCustomer),
      switchMap(({ id }) =>
        this.customersService.get(id).pipe(
          map((customer: ICustomer) => fromActions.selectCustomerComplete({ customer })),
          catchError(({ error }) => of(fromActions.selectCustomerError({ error }))),
        ),
      ),
    ),
  );

  onUpdateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCustomer),
      switchMap(({ id, customer }) =>
        this.customersService.update(id, { ...customer }).pipe(
          map((customer: ICustomer) => fromActions.updateCustomerComplete({ customer })),
          catchError(({ error }) => of(fromActions.updateCustomerError({ error }))),
        ),
      ),
    ),
  );

  onUpdateCustomerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCustomerComplete, fromActions.activateCustomerComplete, fromActions.deactivateCustomerComplete),
      map(() => fromActions.getCustomersList()),
    ),
  );

  onAddCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomer),
      switchMap(({ customer }) =>
        this.customersService.create({ ...customer }).pipe(
          map((customer: ICustomer) => fromActions.addCustomerComplete({ customer, redirectUrl: `customers/${customer.id}/details` })),
          catchError(({ error }) => of(fromActions.addCustomerError({ error }))),
        ),
      ),
    ),
  );

  onAddCustomerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomerComplete),
      tap(({ redirectUrl }) => this.router.navigate([redirectUrl])),
      map(() => fromActions.getCustomersList()),
    ),
  );

  onShowAddCustomerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomerComplete),
      map(() => showCustomNotification({ message: 'Customer Added: Please, review the details.' })),
    ),
  );

  onShowError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectCustomerError, fromActions.addCustomerError, fromActions.updateCustomerError),
      map(({ error }) => showCustomNotification({ message: error.errorMessage })),
    ),
  );

  onActivateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateCustomer),
      switchMap(({ id }) =>
        this.customersService.activate(id).pipe(
          map(customer => fromActions.activateCustomerComplete({ customer })),
          catchError(({ error }) => of(fromActions.activateCustomerError({ error }))),
        ),
      ),
    ),
  );

  onShowActivateCustomerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.activateCustomerComplete),
      map(() => showCustomNotification({ message: 'Customer activated for Cloud Hub.' })),
    ),
  );

  onDeactivateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deactivateCustomer),
      switchMap(({ id }) =>
        this.customersService.deactivate(id).pipe(
          map(customer => fromActions.deactivateCustomerComplete({ customer })),
          catchError(({ error }) => of(fromActions.deactivateCustomerError({ error }))),
        ),
      ),
    ),
  );

  onShowDeactivateCustomerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deactivateCustomerComplete),
      map(() => showCustomNotification({ message: 'Customer deactivated for Cloud Hub.' })),
    ),
  );

  onRefreshCustomerEnvironments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.refreshCustomerEnvironments),
      switchMap(({ entraTenantId, customerId }) =>
        this.environmentsService.refresh({ tenantId: entraTenantId }).pipe(
          map(res => fromActions.refreshCustomerEnvironmentsComplete({ res, customerId })),
          catchError(error => of(fromActions.refreshCustomerEnvironmentsError({ error }))),
        ),
      ),
    ),
  );

  onRefreshCustomerEnvironmentsComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.refreshCustomerEnvironmentsComplete),
      tap(() =>
        this.dialogService.openDialog<ConfirmationDialogData, undefined>(ConfirmationDialogComponent, {
          title: 'Refresh customer environments data',
          description:
            'Request to refresh customer environments data added to queue. Updated data will be visible once request completes successfully.',
          cancelText: 'OK',
          confirmText: '',
          showCloseBtn: false,
        }),
      ),
      map(({ customerId }) => fromActions.selectCustomer({ id: customerId })),
    ),
  );

  onGetAvailableCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAvailableCountries),
      switchMap(({ customerCountryId }) =>
        this.customersService.getAvailableCountries(customerCountryId).pipe(
          map(countries => fromActions.getAvailableCountriesComplete({ countries })),
          catchError(err => of(fromActions.getAvailableCountriesError({ err }))),
        ),
      ),
    ),
  );

  getTenantEnvironmentQuota$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getTenantEnvironmentQuota),
      switchMap(({ customerTenantId }) =>
        this.customersService.getTenantEnvironmentQuota(customerTenantId).pipe(
          map(quotaInfo => fromActions.getTenantEnvironmentQuotaComplete({ quotaInfo })),
          catchError(error => of(fromActions.getTenantEnvironmentQuotaError({ error }))),
        ),
      ),
    ),
  );

  onGoToList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToList),
      tap(() => this.router.navigate(['customers'])),
      map(() => fromActions.resetState({ selectedCustomer: null })),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly customersService: CustomersService,
    private readonly dialogService: DialogService,
    private readonly environmentsService: EnvironmentsService,
  ) {}
}
