import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
    name: 'isoDateWithTime', pure: true,
    standalone: false
})
export class IsoDateWithTimePipe implements PipeTransform {
  transform(value: Date): string {
    return format(new Date(value), 'yyyy-MM-dd HH:mm');
  }
}
