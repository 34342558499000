import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface CustomerForm {
  name: FormControl<string>;
  street: FormControl<string>;
  city: FormControl<string>;
  postCode: FormControl<string>;
  countryId: FormControl<string>;
  stateId: FormControl<string | null>;
  applicationActivated: FormControl<string>;
  cspRelationInactive: FormControl<string>;
  entraTenantId: FormControl<string | null>;
}

@Injectable()
export class CustomerFormBaseFormBuilder {
  constructor(private readonly formBuilder: FormBuilder) {}

  build(): FormGroup<CustomerForm> {
    return this.formBuilder.group<CustomerForm>({
      name: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.maxLength(150)] }),
      street: new FormControl('', { nonNullable: true, validators: [Validators.maxLength(150)] }),
      city: new FormControl('', { nonNullable: true, validators: [Validators.maxLength(30)] }),
      postCode: new FormControl('', { nonNullable: true, validators: [Validators.maxLength(30)] }),
      countryId: new FormControl('', { nonNullable: true }),
      stateId: new FormControl('', { nonNullable: true }),
      applicationActivated: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
      cspRelationInactive: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
      entraTenantId: new FormControl({ value: '', disabled: true }, { nonNullable: true }),
    });
  }
}
