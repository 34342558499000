import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { customersActions, environmentsSelectors, EnvironmentsToggleOptions } from '@appState';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { DialogService } from 'cui-components';
import { catchError, map, of, switchMap, take, tap } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationDialogData } from 'src/app/components/dialogs';
import { AppState } from '../../app.store';
import * as fromActions from './environments.actions';
import { EnvironmentsService } from './environments.service';

@Injectable()
export class EnvironmentsEffects {
  onGetEnvironments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllEnvironments),
      switchMap(({ params }) =>
        this.environmentsService.getAll(params).pipe(
          map(environments => fromActions.getAllEnvironmentsComplete({ environments })),
          catchError(err => of(fromActions.getAllEnvironmentsError({ err }))),
        ),
      ),
    ),
  );

  onSelectEnvironment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectEnvironment),
      switchMap(({ environmentId }) =>
        this.environmentsService.getOne(environmentId).pipe(
          map(environment => fromActions.selectEnvironmentComplete({ environment })),
          catchError(err => of(fromActions.selectEnvironmentError({ err }))),
        ),
      ),
    ),
  );

  onGoToList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToList, fromActions.goToListWithFilters),
      tap(props =>
        props['appId'] ? this.router.navigate(['apps', props['appId'], 'environments']) : this.router.navigate(['environments']),
      ),
      map(() => fromActions.resetState({ selectedEnvironment: null })),
    ),
  );

  onSetEnvironmentUpdateDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setEnvironmentUpdateDate),
      switchMap(({ environmentId, runOnDate, runOnTime, ignoreUpgradeWindow }) =>
        this.environmentsService
          .setUpdateDate(environmentId, {
            runOn: this.getISOString(runOnDate!, runOnTime),
            ignoreUpgradeWindow,
          })
          .pipe(
            map(res => fromActions.setEnvironmentUpdateDateComplete({ environmentId, res })),
            catchError(err => of(fromActions.setEnvironmentUpdateDateError({ err }))),
          ),
      ),
    ),
  );

  onSetEnvironmentUpdatDateComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setEnvironmentUpdateDateComplete),
      tap(({ environmentId }) => {
        this.router.navigate(['environments', environmentId]);
        this.dialogService.openDialog<ConfirmationDialogData, undefined>(ConfirmationDialogComponent, {
          title: 'Set update date',
          description: 'Request to modify update date added to queue. Changes will be visible once request completes successfully.',
          cancelText: 'OK',
          confirmText: '',
          showCloseBtn: false,
        });
      }),
      map(({ environmentId }) => fromActions.selectEnvironment({ environmentId })),
    ),
  );

  onSetEnvironmentUpdateWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setEnvironmentUpdateWindow),
      switchMap(({ environmentId, preferredStartTime, preferredEndTime }) =>
        this.environmentsService
          .setUpdateWindow(environmentId, {
            preferredStartTimeUtc: `${this.getTimeInUtcString(preferredStartTime)}:00.000`,
            preferredEndTimeUtc: `${this.getTimeInUtcString(preferredEndTime)}:00.000`,
          })
          .pipe(
            map(res => fromActions.setEnvironmentUpdateWindowComplete({ environmentId, res })),
            catchError(err => of(fromActions.setEnvironmentUpdateWindowError({ err }))),
          ),
      ),
    ),
  );

  onSetEnvironmentUpdateWindowComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setEnvironmentUpdateWindowComplete),
      tap(({ environmentId }) => {
        this.router.navigate(['environments', environmentId]);
        this.dialogService.openDialog<ConfirmationDialogData, undefined>(ConfirmationDialogComponent, {
          title: 'Set update window',
          description: 'Request to modify update window added to queue. Changes will be visible once request completes successfully.',
          cancelText: 'OK',
          confirmText: '',
          showCloseBtn: false,
        });
      }),
      map(({ environmentId }) => fromActions.selectEnvironment({ environmentId })),
    ),
  );

  onEnvironmentsRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.environmentsRefresh),
      switchMap(({ environmentId, req }) =>
        this.environmentsService.refresh(req).pipe(
          map(res => fromActions.environmentsRefreshComplete({ environmentId, res })),
          catchError(err => of(fromActions.environmentsRefreshError({ err }))),
        ),
      ),
    ),
  );

  onEnvironmentsRefreshComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.environmentsRefreshComplete),
      tap(() =>
        this.dialogService.openDialog<ConfirmationDialogData, undefined>(ConfirmationDialogComponent, {
          title: 'Refresh environment data',
          description:
            'Request to refresh environment data added to queue. Updated data will be visible once request completes successfully.',
          cancelText: 'OK',
          confirmText: '',
          showCloseBtn: false,
        }),
      ),
      map(({ environmentId }) => fromActions.selectEnvironment({ environmentId })),
    ),
  );

  onCreateEnvironment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createEnvironment),
      switchMap(({ request }) =>
        this.environmentsService.create(request).pipe(
          map(res => fromActions.createEnvironmentComplete({ res })),
          catchError(err => of(fromActions.createEnvironmentError({ err, customerTenantId: request.customerTenantId }))),
        ),
      ),
    ),
  );

  onCreateEnvironmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createEnvironmentComplete),
      map(({ res }) => customersActions.getTenantEnvironmentQuota({ customerTenantId: res.tenantId })),
    ),
  );

  onCreateEnvironmentError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createEnvironmentError, fromActions.copyEnvironmentError),
      map(({ customerTenantId }) => customersActions.getTenantEnvironmentQuota({ customerTenantId })),
    ),
  );

  onShowCreateEnvironmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createEnvironmentComplete),
      map(() => showCustomNotification({ message: 'New environment created successfully.' })),
    ),
  );

  onCopyEnvironment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.copyEnvironment),
      switchMap(({ environmentId, request, customerTenantId }) =>
        this.environmentsService.copy(environmentId, request).pipe(
          map(res => fromActions.copyEnvironmentComplete({ res })),
          catchError(err => of(fromActions.copyEnvironmentError({ err, customerTenantId }))),
        ),
      ),
    ),
  );

  onCopyEnvironmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.copyEnvironmentComplete),
      switchMap(() =>
        this.store.pipe(
          select(environmentsSelectors.selectToggle),
          take(1),
          map(toggle => fromActions.getAllEnvironments({ params: { onlyActive: toggle === EnvironmentsToggleOptions.Active } })),
        ),
      ),
    ),
  );

  onShowCopyEnvironmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.copyEnvironmentComplete),
      tap(({ res }) => this.router.navigate(['environments', res.id, 'details'])),
      map(() => showCustomNotification({ message: 'Environment copied successfully.' })),
    ),
  );

  getTimeInUtcString(localTime: string): string {
    return getDateTimeFromTime(localTime).toISOString().split('T')[1].slice(0, 5);
  }

  getISOString(date: Date, time: string): string {
    const [hours, minutes] = time.split(':');
    date.setHours(+hours);
    date.setMinutes(+minutes);
    return date.toISOString();
  }

  constructor(
    private readonly actions$: Actions,
    private readonly environmentsService: EnvironmentsService,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly store: Store<AppState>,
  ) {}
}

export function getDateTimeFromTime(time: string): Date {
  const dateTime = new Date();
  const [hours, minutes] = time.split(':');
  dateTime.setHours(+hours);
  dateTime.setMinutes(+minutes);
  dateTime.setSeconds(0);
  dateTime.setMilliseconds(0);
  return dateTime;
}
