import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActionQueuedResponse,
  CopyEnvironmentRequest,
  CreateEnvironmentRequest,
  Environment,
  EnvironmentListItem,
  EnvironmentListItemResponse,
  EnvironmentResponse,
  EnvironmentsQueryParams,
  EnvironmentsRefreshRequest,
  SetUpdateDateRequest,
  SetUpdateWindowRequest,
} from './environment.model';

@Injectable()
export class EnvironmentsService {
  private readonly url = 'environments';
  constructor(private highlanderService: HighlanderService) {}

  getAll(params: EnvironmentsQueryParams): Observable<EnvironmentListItem[]> {
    return this.highlanderService.get<EnvironmentListItemResponse[]>(this.url, {
      params,
    });
  }

  getOne(environmentId: string): Observable<Environment> {
    return this.highlanderService.get<EnvironmentResponse>(`${this.url}/${environmentId}`);
  }

  setUpdateDate(environmentId: string, req: SetUpdateDateRequest): Observable<ActionQueuedResponse> {
    return this.highlanderService.post<SetUpdateDateRequest, ActionQueuedResponse>(`${this.url}/${environmentId}/set-update-date`, req);
  }

  setUpdateWindow(environmentId: string, req: SetUpdateWindowRequest): Observable<ActionQueuedResponse> {
    return this.highlanderService.post<SetUpdateWindowRequest, ActionQueuedResponse>(`${this.url}/${environmentId}/set-update-window`, req);
  }

  refresh(req: EnvironmentsRefreshRequest): Observable<ActionQueuedResponse> {
    return this.highlanderService.post<EnvironmentsRefreshRequest, ActionQueuedResponse>(`${this.url}/refresh`, req);
  }

  create(request: CreateEnvironmentRequest): Observable<Environment> {
    return this.highlanderService.post<CreateEnvironmentRequest, EnvironmentResponse>(this.url, request);
  }

  copy(environmentId: string, request: CopyEnvironmentRequest): Observable<Environment> {
    return this.highlanderService.post<CopyEnvironmentRequest, EnvironmentResponse>(`${this.url}/${environmentId}/copy`, request);
  }
}
