import { createSelector } from '@ngrx/store';
import { getRootState } from '../app.store';
import { CommonDataType } from './common-data.model';
import * as fromReducer from './common-data.reducer';

export const selectModuleState = createSelector(getRootState, rootState => rootState.commonData);
export const { selectAll } = fromReducer.adapter.getSelectors(selectModuleState);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectCommonDataList = createSelector(selectAll, commonDataList => commonDataList ?? []);
export const selectCommonDataListByType = (type: CommonDataType) =>
  createSelector(selectAll, commonDataList => (commonDataList ?? []).filter(data => data.type === type));
export const selectCommonDataById = (id: string) => createSelector(selectAll, commonDataList => commonDataList.find(x => x.id === id));

export const selectMapCommonDataWithChoices = createSelector(selectCommonDataList, data =>
  data.map(x => ({ value: x.id, text: x.details.name })),
);
