import { Observable, combineLatest, filter, of, switchMap, take } from 'rxjs';

export const sortItems = <T>(array: T[], sorting: (a: T, b: T) => number): T[] => {
  return array.slice().sort((a, b) => sorting(a, b));
};

export function findAndRemove<T>(array: T[], predicate: (item: T) => boolean): T[] {
  const index = array.findIndex(predicate);
  const newArray = [...array];
  if (index !== -1) {
    newArray.splice(index, 1);
  }
  return newArray;
}

export function retryEntityFetch<T>(
  entityObs: Observable<T | null>,
  loadingObs: Observable<boolean>,
  shouldRetry: (arg: T) => boolean,
  dispatchRetry: () => void,
): Observable<T | null> {
  let repeats = 0;
  return combineLatest({
    entity: entityObs,
    loading: loadingObs,
  }).pipe(
    filter(({ loading }) => !loading),
    switchMap(({ entity }) => {
      if (shouldRetry(entity as T) && repeats === 0) {
        repeats++;
        dispatchRetry();
        return entityObs.pipe(
          take(0),
          switchMap(() => of(entity)),
        );
      }
      return of(entity);
    }),
  );
}
