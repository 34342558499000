import { findAndReplace } from '@highlander/common/helpers/util';
import { createReducer, on } from '@ngrx/store';
import { sortItems } from '../helpers/misc';
import { IAssessment } from '../mm-program/assessment/assessment.model';
import { ProductRequest } from './request.model';
import * as fromActions from './requests.actions';

export interface State {
  isLoading: boolean;
  requests: ProductRequest[];

  selectedRequest: ProductRequest | null;
  selectedRequestLoading: boolean;

  selectedAssessmentLoading: boolean;
  selectedAssessment: IAssessment | null;
  assessmentSaveCompleted: boolean;

  selectedProductDescription: string | null;
  selectedProductDescriptionLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
  requests: [],

  selectedRequest: null,
  selectedRequestLoading: false,

  selectedAssessmentLoading: false,
  selectedAssessment: null,
  assessmentSaveCompleted: false,

  selectedProductDescription: null,
  selectedProductDescriptionLoading: false,
};

export const requestsReducer = createReducer(
  initialState,

  on(fromActions.resetState, (state, providedState) => ({ ...state, ...providedState })),

  on(fromActions.getAllMMRequests, state => ({
    ...state,
    isLoading: true,
  })),
  on(fromActions.getAllMMRequestsComplete, (state, { requests }) => ({
    ...state,
    requests: sortItems<ProductRequest>(requests, (a, b) => {
      const dateA = a.lastUpdatedBy?.date || a.createdBy.date;
      const dateB = b.lastUpdatedBy?.date || b.createdBy.date;

      return dateA < dateB ? 1 : -1;
    }),
    isLoading: false,
  })),
  on(fromActions.getAllMMRequestsError, state => ({ ...state, isLoading: false })),

  on(fromActions.addMMRequest, state => ({ ...state, selectedRequestLoading: true, isLoading: true })),
  on(fromActions.addMMRequestComplete, (state, { request }) => ({
    ...state,
    selectedRequestLoading: false,
    selectedRequest: request,
    requests: sortItems<ProductRequest>([...state.requests, request], (a, b) => {
      const dateA = a.lastUpdatedBy?.date || a.createdBy.date;
      const dateB = b.lastUpdatedBy?.date || b.createdBy.date;
      return dateA < dateB ? 1 : -1;
    }),
    isLoading: false,
  })),
  on(fromActions.addMMRequestError, state => ({ ...state, selectedRequestLoading: false, isLoading: false })),

  on(fromActions.selectMMRequest, state => ({ ...state, selectedRequestLoading: true, selectedRequest: null })),
  on(fromActions.selectMMRequestComplete, (state, { request }) => ({
    ...state,
    selectedRequest: request,
    selectedRequestLoading: false,
  })),
  on(fromActions.selectMMRequestError, state => ({ ...state, selectedRequestLoading: false })),

  on(fromActions.selectMMRequestAssessment, state => ({ ...state, selectedAssessmentLoading: true })),
  on(fromActions.selectMMRequestAssessmentComplete, (state, { assessment }) => ({
    ...state,
    selectedAssessment: assessment,
    assessmentSaveCompleted: false,
    selectedAssessmentLoading: false,
  })),
  on(fromActions.selectMMRequestAssessmentError, state => ({
    ...state,
    selectedAssessmentLoading: false,
  })),

  on(fromActions.updateMMRequest, state => ({ ...state, selectedRequestLoading: true })),
  on(fromActions.updateMMRequestComplete, (state, { request }) => ({
    ...state,
    selectedRequestLoading: false,
    selectedRequest: request,
    requests: findAndReplace<ProductRequest>(state.requests, request, item => item.id === request.id),
  })),
  on(fromActions.updateMMRequestError, state => ({ ...state, selectedRequestLoading: false })),

  on(fromActions.updateMMRequestAssessment, state => ({
    ...state,
    selectedAssessmentLoading: true,
    isLoading: true,
    assessmentSaveCompleted: false,
  })),
  on(fromActions.updateMMRequestAssessmentComplete, (state, { assessment, isDraft }) => ({
    ...state,
    isLoading: false,
    selectedAssessmentLoading: false,
    selectedAssessment: assessment,
    assessmentSaveCompleted: !isDraft,
  })),
  on(fromActions.updateMMRequestAssessmentError, state => ({
    ...state,
    isLoading: false,
    selectedAssessmentLoading: false,
    assessmentSaveCompleted: false,
  })),

  on(fromActions.submitMMRequestAssessment, state => ({ ...state, selectedAssessmentLoading: true, assessmentSaveCompleted: false })),
  on(fromActions.submitMMRequestAssessmentComplete, (state, { assessment }) => ({
    ...state,
    selectedAssessmentLoading: false,
    selectedAssessment: assessment,
    assessmentSaveCompleted: false,
  })),
  on(fromActions.submitMMRequestAssessmentError, state => ({ ...state, selectedAssessmentLoading: false, assessmentSaveCompleted: false })),

  on(fromActions.deleteSelectedMMRequest, state => ({ ...state, selectedRequestLoading: true })),
  on(fromActions.deleteSelectedMMRequestComplete, state => ({
    ...state,
    selectedRequestLoading: false,
    selectedRequest: null,
  })),
  on(fromActions.deleteSelectedMMRequestError, state => ({
    ...state,
    selectedRequestLoading: false,
  })),

  on(fromActions.resetAssessmentSaveCompleted, state => ({ ...state, assessmentSaveCompleted: false })),

  on(fromActions.getProductDescription, state => ({
    ...state,
    selectedProductDescriptionLoading: true,
    selectedProductDescription: null,
  })),
  on(fromActions.getProductDescriptionComplete, (state, { description }) => ({
    ...state,
    selectedProductDescription: description,
    selectedProductDescriptionLoading: false,
  })),
  on(fromActions.getProductDescriptionError, state => ({
    ...state,
    selectedProductDescriptionLoading: false,
  })),
);
