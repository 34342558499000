import { showCustomNotification } from '@1clickfactory/notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ICustomer, requestsActions, requestsSelectors } from '@appState';
import { getFilterFromLocalStorage } from '@highlander/common/helpers/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { isNotNullOrUndefined } from 'cui-components';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';
import { REQUESTS_TABLE_ID } from 'src/app/requests/requests.component';
import { AppState } from '../app.store';
import { CustomersService } from '../customers/customers.service';
import { AssessmentsService } from '../mm-program/assessment/assessments.service';
import { ProductsService } from '../products/products.service';
import { RequestsToggleOptions } from './request.model';
import * as fromActions from './requests.actions';
import { RequestsService } from './requests.service';

@Injectable()
export class RequestsEffects {
  onGetMMRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getAllMMRequests),
      switchMap(({ toggle }) =>
        this.requestsService.getAll(toggle === RequestsToggleOptions.All ? undefined : true).pipe(
          map(requests => fromActions.getAllMMRequestsComplete({ requests })),
          catchError(err => of(fromActions.getAllMMRequestsError({ err }))),
        ),
      ),
    ),
  );

  onSelectMMRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectMMRequest),
      switchMap(({ id }) =>
        this.requestsService.get(id).pipe(
          map(request => fromActions.selectMMRequestComplete({ request })),

          catchError(err => of(fromActions.selectMMRequestError({ err }))),
        ),
      ),
    ),
  );

  onSelectMMRequestAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectMMRequestAssessment),
      switchMap(({ id }) =>
        this.assessmentsService.get(id).pipe(
          map(assessment =>
            fromActions.selectMMRequestAssessmentComplete({ assessment: { actions: assessment.actions, ...assessment.assessment } }),
          ),
          catchError(({ error }) => of(fromActions.selectMMRequestAssessmentError({ error }))),
        ),
      ),
    ),
  );

  onAddMMRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addMMRequest),
      switchMap(({ assessment }) =>
        this.requestsService.add(assessment).pipe(
          map(request => fromActions.addMMRequestComplete({ request })),
          catchError(err => of(fromActions.addMMRequestError({ err }))),
        ),
      ),
    ),
  );

  onAddMMRequestComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addMMRequestComplete),
        switchMap(response => this.router.navigate(['requests', response.request.id, 'general-info'])),
      ),
    { dispatch: false },
  );

  onShowAddAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addMMRequestComplete),
      map(() => showCustomNotification({ message: 'Request created: Please, review the general information.' })),
    ),
  );

  onUpdateMMRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateMMRequest),
      switchMap(({ id, request }) =>
        this.requestsService.update(id, request).pipe(
          map(request => fromActions.updateMMRequestComplete({ request })),
          catchError(err => of(fromActions.updateMMRequestError({ err }))),
        ),
      ),
    ),
  );

  onUpdateMMRequestAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateMMRequestAssessment),
      switchMap(({ id, assessment, isDraft }) =>
        this.assessmentsService.update(id, { ...assessment }).pipe(
          map(assessment =>
            fromActions.updateMMRequestAssessmentComplete({
              assessment: { actions: assessment.actions, ...assessment.assessment },
              isDraft,
            }),
          ),
          catchError(({ error }) => of(fromActions.updateMMRequestAssessmentError({ error }))),
        ),
      ),
    ),
  );

  onUpdateMMRequestAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateMMRequestAssessmentComplete),
      map(() => this.getRequestsBasedOnFilter()),
    ),
  );

  onSubmitMMRequestAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitMMRequestAssessment),
      switchMap(() =>
        this.store.pipe(
          select(requestsSelectors.selectSelectedExternalProductId),
          take(1),
          switchMap(id =>
            this.assessmentsService.submit(id!).pipe(
              map(assessment =>
                fromActions.submitMMRequestAssessmentComplete({
                  assessment: { actions: assessment.actions, ...assessment.assessment },
                }),
              ),
              catchError(({ error }) => of(fromActions.submitMMRequestAssessmentError({ error }))),
            ),
          ),
        ),
      ),
    ),
  );

  onSubmitMMRequestAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitMMRequestAssessmentComplete),
      tap(() => this.router.navigate(['requests'])),
      map(() => this.getRequestsBasedOnFilter()),
    ),
  );

  onShowSubmitMMRequestAssessmentComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.submitMMRequestAssessmentComplete),
      map(() => showCustomNotification({ message: 'Request successfully submitted.' })),
    ),
  );

  onDeleteMMRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedMMRequest),
      switchMap(() =>
        this.store.pipe(
          select(requestsSelectors.selectSelectedRequest),
          filter(isNotNullOrUndefined),
          take(1),
          switchMap(request =>
            this.assessmentsService.delete(request.product.externalId).pipe(
              map(() => fromActions.deleteSelectedMMRequestComplete()),
              catchError(err => of(fromActions.deleteSelectedMMRequestError({ err }))),
            ),
          ),
        ),
      ),
    ),
  );

  onDeleteMMRequestComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSelectedMMRequestComplete),
      tap(() => this.router.navigate(['requests'])),
      map(() => this.getRequestsBasedOnFilter()),
    ),
  );

  onAddCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomer),
      switchMap(({ customer }) =>
        this.customersService.create({ ...customer }).pipe(
          map((customer: ICustomer) => fromActions.addCustomerComplete({ customer })),
          catchError(({ error }) => of(fromActions.addCustomerError({ error }))),
        ),
      ),
    ),
  );

  onAddCustomerComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addCustomerComplete),
      map(({ customer }) => fromActions.addMMRequest({ assessment: { customerId: customer.id } })),
    ),
  );

  onGoToMMRequestActions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goToMMRequestActions),
        tap(({ id }) => this.router.navigate(['requests', id])),
      ),
    { dispatch: false },
  );

  onGoToListPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.goToListPage),
      tap(() => this.router.navigate(['requests'])),
      map(() => fromActions.resetState({ selectedRequest: null })),
    ),
  );

  onGetProductDescription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getProductDescription),
      switchMap(({ id }) =>
        this.productsService.getProductDescription(id).pipe(
          map(product => fromActions.getProductDescriptionComplete({ description: product.description })),
          catchError(error => of(fromActions.getProductDescriptionError({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private requestsService: RequestsService,
    private assessmentsService: AssessmentsService,
    private customersService: CustomersService,
    private productsService: ProductsService,
  ) {}

  private getRequestsBasedOnFilter(): ReturnType<typeof requestsActions.getAllMMRequests> {
    const filterSettings = getFilterFromLocalStorage(REQUESTS_TABLE_ID);
    return requestsActions.getAllMMRequests({
      toggle: filterSettings?.toggleSearch === 'All' ? RequestsToggleOptions.All : RequestsToggleOptions.Active,
    });
  }
}
