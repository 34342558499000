import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { App, AppResponse, AppsQueryParams } from './app.model';

@Injectable({ providedIn: 'root' })
export class AppsService {
  private readonly url = 'apps';
  constructor(private highlanderService: HighlanderService) {}

  getAll(params?: AppsQueryParams): Observable<App[]> {
    return this.highlanderService.get<AppResponse[]>(this.url, { params });
  }
  getOne(appId: string): Observable<App> {
    return this.highlanderService.get<AppResponse>(`${this.url}/${appId}`);
  }
}
