import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
    selector: 'ocf-form-pending-notices',
    template: `
    <ocf-alert type="warning" i18n *ngIf="contactsPending && !filesPending">
      Please finish editing contact list before saving or submitting the form.
    </ocf-alert>

    <ocf-alert type="warning" i18n *ngIf="!contactsPending && filesPending">
      Please wait for files to finish uploading before saving or submitting the form.
    </ocf-alert>

    <ocf-alert type="warning" i18n *ngIf="contactsPending && filesPending">
      Please wait for files to finish uploading and complete filling out "additional contacts" before saving or submitting the form.
    </ocf-alert>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OcfFormPendingNoticesComponent {
  @Input() contactsPending = false
  @Input() filesPending = false
}
