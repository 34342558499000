import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { identitySelectors, requestsActions, requestsSelectors } from '@appState';
import { Store, select } from '@ngrx/store';
import { ColDef } from 'ag-grid-community';
import { CuiTableModule, DialogService, formatDate } from 'cui-components';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import { ProductRequest, RequestsToggleOptions } from '../app-state/requests/request.model';
import { NewAssessmentModalComponent } from '../mm-program/components/add-assessment/add-assessment-modal.component';

export const REQUESTS_TABLE_ID = 'requests-table';

@Component({
  standalone: true,
  imports: [CommonModule, CuiTableModule, RouterModule],
  selector: 'ocf-requests-list',
  template: ` <cui-table
      [tableId]="REQUESTS_TABLE_ID"
      id="requests-table"
      [columnDefs]="columnDefs"
      [isLoading]="(isLoading$ | async)!"
      [selectedRowId]="(selectedRequestId$ | async)!"
      [rowData]="(rowData$ | async)!"
      [toggleDefaultOption]="toggleOptions[0]"
      [toggleOptions]="toggleOptions"
      (rowClicked)="onRowClick($event)"
      (toggleClicked)="onToggleClick($event)"
      [ctaText]="'Add'"
      (ctaClicked)="onAddClick()"
      [ctaDisabled]="(canAddRequest$ | async) === false"
    ></cui-table>

    <router-outlet></router-outlet>`,
  styleUrls: ['./requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestsComponent {
  isLoading$: Observable<boolean> = this.store.pipe(select(requestsSelectors.selectIsLoading));
  rowData$: Observable<ProductRequest[]> = this.store.pipe(select(requestsSelectors.selectRequests));
  selectedRequestId$: Observable<string | null> = this.store.pipe(select(requestsSelectors.selectSelectedRequestId));
  toggleOptions: RequestsToggleOptions[] = [RequestsToggleOptions.Active, RequestsToggleOptions.All];
  canAddRequest$: Observable<boolean> = this.store.pipe(
    select(identitySelectors.selectHasPermission(['Portal.Business.ProductsAndOrders.Requests.Create'])),
  );

  REQUESTS_TABLE_ID = REQUESTS_TABLE_ID;

  columnDefs: ColDef<ProductRequest>[] = [
    {
      field: 'product',
      minWidth: 150,
      valueFormatter: params => (params.value?.name ? params.value.name : 'N/A'),
      getQuickFilterText: params => params.value?.id + '|' + params.value?.name,
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressAndOrCondition: true,
      },
    },
    {
      field: 'customer',
      minWidth: 150,
      valueFormatter: params => (params.value?.name ? params.value.name : 'N/A'),
      getQuickFilterText: params => params.value?.id + '|' + params.value?.name,
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressAndOrCondition: true,
      },
    },
    {
      field: 'description',
      minWidth: 200,
    },
    {
      headerName: 'Request date',
      valueGetter: params => params.data?.submissionDate ?? params.data?.lastUpdatedBy?.date,
      minWidth: 150,
    },
    {
      field: 'expirationDate',
      valueFormatter: params => (params.value ? formatDate(params.value) : ''),
      minWidth: 150,
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressAndOrCondition: true,
      },
    },
    {
      field: 'lastUpdatedBy',
      minWidth: 150,
      valueFormatter: params => params.data?.lastUpdatedBy?.email ?? '',
    },
    {
      field: 'status',
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],
      filterParams: {
        applyMiniFilterWhileTyping: true,
        suppressAndOrCondition: true,
      },
      minWidth: 150,
    },
    {
      field: 'id',
      hide: true,
    },
  ];

  constructor(
    private store: Store<AppState>,
    private readonly dialogService: DialogService,
    private readonly router: Router,
  ) {}

  onAddClick(): void {
    this.dialogService.openDialog<undefined, NewAssessmentModalComponent>(NewAssessmentModalComponent, undefined);
  }

  onRowClick({ id }: ProductRequest): void {
    this.router.navigate(['requests', id]);
  }

  onToggleClick(toggle: RequestsToggleOptions): void {
    this.store.dispatch(requestsActions.getAllMMRequests({ toggle }));
  }
}
