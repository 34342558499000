import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AvailableCountryChoice,
  CustomerCreateRequest,
  CustomerResponse,
  CustomerUpdateRequest,
  TenantEnvironmentQuota,
} from './customer.model';

@Injectable()
export class CustomersService {
  private readonly path = (url = ''): string => `customers/${url}`;
  private readonly environmentPath = (url = ''): string => `environments/${url}`;

  constructor(private highlanderService: HighlanderService) {}

  getAll(): Observable<CustomerResponse[]> {
    return this.highlanderService.get<CustomerResponse[]>(this.path());
  }

  get(id: string): Observable<CustomerResponse> {
    return this.highlanderService.get<CustomerResponse>(this.path(`${id}`));
  }

  create(model: CustomerCreateRequest): Observable<CustomerResponse> {
    return this.highlanderService.post<CustomerCreateRequest, CustomerResponse>(this.path(), model);
  }

  update(id: string, model: CustomerUpdateRequest): Observable<CustomerResponse> {
    return this.highlanderService.put<CustomerUpdateRequest, CustomerResponse>(this.path(`${id}`), model);
  }

  activate(id: string): Observable<CustomerResponse> {
    return this.highlanderService.post<void, CustomerResponse>(this.path(`${id}/activate`));
  }

  deactivate(id: string): Observable<CustomerResponse> {
    return this.highlanderService.post<void, CustomerResponse>(this.path(`${id}/deactivate`));
  }

  getTenantEnvironmentQuota(customerTenantId: string): Observable<TenantEnvironmentQuota> {
    return this.highlanderService.get<TenantEnvironmentQuota>(this.environmentPath(`tenants/${customerTenantId}/quota`));
  }

  getAvailableCountries(customerCountryId: string): Observable<AvailableCountryChoice[]> {
    return this.highlanderService.get<AvailableCountryChoice[]>(this.environmentPath(`countries/${customerCountryId}/available-countries`));
  }
}
