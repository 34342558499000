import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

export interface AddAssessmentForm {
    customerId: FormControl<string>
  }

@Injectable()
export class AddAssessmentModalFormBuilder {
  constructor(private readonly formBuilder: FormBuilder) {}

  build(): FormGroup<AddAssessmentForm> {
    return this.formBuilder.group<AddAssessmentForm>({
      customerId: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
    });
  }
}