import { HighlanderCoreErrorResponse } from '@1clickfactory/data-access';
import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ActionQueuedResponse } from '../dynamics-arc/environments/environment.model';
import { AvailableCountryChoice, CustomerCreateRequest, CustomerUpdateRequest, ICustomer, TenantEnvironmentQuota } from './customer.model';
import { State } from './customers.reducer';

const NAMESPACE = '[Customers]';

export const getCustomersList = createAction(`${NAMESPACE} GetCustomersList`);
export const getCustomersListComplete = createAction(`${NAMESPACE} GetCustomersListComplete`, props<{ customers: ICustomer[] }>());
export const getCustomersListError = createAction(`${NAMESPACE} GetCustomersListError`, props<{ error: HighlanderCoreErrorResponse }>());

export const selectCustomer = createAction(`${NAMESPACE} GetCustomer`, props<{ id: string }>());
export const selectCustomerComplete = createAction(`${NAMESPACE} GetCustomerComplete`, props<{ customer: ICustomer }>());
export const selectCustomerError = createAction(`${NAMESPACE} GetCustomerError`, props<{ error: HighlanderCoreErrorResponse }>());

export const addCustomer = createAction(`${NAMESPACE} AddCustomer`, props<{ customer: CustomerCreateRequest }>());
export const addCustomerComplete = createAction(`${NAMESPACE} AddCustomerComplete`, props<{ customer: ICustomer; redirectUrl: string }>());
export const addCustomerError = createAction(`${NAMESPACE} AddCustomerError`, props<{ error: HighlanderCoreErrorResponse }>());

export const updateCustomer = createAction(`${NAMESPACE} UpdateCustomer`, props<{ id: string; customer: CustomerUpdateRequest }>());
export const updateCustomerComplete = createAction(`${NAMESPACE} UpdateCustomerComplete`, props<{ customer: ICustomer }>());
export const updateCustomerError = createAction(`${NAMESPACE} UpdateCustomerError`, props<{ error: HighlanderCoreErrorResponse }>());

export const activateCustomer = createAction(`${NAMESPACE} ActivateCustomer`, props<{ id: string }>());
export const activateCustomerComplete = createAction(`${NAMESPACE} ActivateCustomerComplete`, props<{ customer: ICustomer }>());
export const activateCustomerError = createAction(`${NAMESPACE} ActivateCustomerError`, props<{ error: HighlanderCoreErrorResponse }>());

export const deactivateCustomer = createAction(`${NAMESPACE} DeactivateCustomer`, props<{ id: string }>());
export const deactivateCustomerComplete = createAction(`${NAMESPACE} DeactivateCustomerComplete`, props<{ customer: ICustomer }>());
export const deactivateCustomerError = createAction(
  `${NAMESPACE} DeactivateCustomerError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const refreshCustomerEnvironments = createAction(
  `${NAMESPACE} RefreshCustomerEnvironments`,
  props<{ entraTenantId: string; customerId: string }>(),
);
export const refreshCustomerEnvironmentsComplete = createAction(
  `${NAMESPACE} RefreshCustomerEnvironmentsComplete`,
  props<{ res: ActionQueuedResponse; customerId: string }>(),
);
export const refreshCustomerEnvironmentsError = createAction(
  `${NAMESPACE} RefreshCustomerEnvironmentsError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const getAvailableCountries = createAction(`${NAMESPACE} GetAvailableCountries`, props<{ customerCountryId: string }>());
export const getAvailableCountriesComplete = createAction(
  `${NAMESPACE} GetAvailableCountriesComplete`,
  props<{ countries: AvailableCountryChoice[] }>(),
);
export const getAvailableCountriesError = createAction(`${NAMESPACE} GetAvailableCountriesError`, props<{ err: HttpErrorResponse }>());

export const getTenantEnvironmentQuota = createAction(`${NAMESPACE} GetTenantEnvironmentQuota`, props<{ customerTenantId: string }>());
export const getTenantEnvironmentQuotaComplete = createAction(
  `${NAMESPACE} GetTenantEnvironmentQuotaComplete`,
  props<{ quotaInfo: TenantEnvironmentQuota }>(),
);
export const getTenantEnvironmentQuotaError = createAction(
  `${NAMESPACE} GetTenantEnvironmentQuotaError`,
  props<{ error: HighlanderCoreErrorResponse }>(),
);

export const goToList = createAction(`${NAMESPACE} GoToList`);
export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
