import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductDescriptionResponse, ProductGenericFileResponse } from './products.model';

@Injectable()
export class ProductsService {
  private readonly path = (url = ''): string => `products/${url}`;

  constructor(private highlanderService: HighlanderService) {}

  getGenericFiles(id: string): Observable<ProductGenericFileResponse[]> {
    return this.highlanderService.get<ProductGenericFileResponse[]>(this.path(`${id}/generic-files`));
  }

  getProductDescription(id: string): Observable<ProductDescriptionResponse> {
    return this.highlanderService.get<ProductDescriptionResponse>(this.path(`${id}/description`));
  }
}
