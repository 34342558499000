import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialDesignModule } from './material-design.module';
import { OcfNoticesModule } from './notices/ocf-notices.module';
import { PipesModule } from './pipes/pipes.module';

/**
 * @deprecated This only re-exports other modules, should be refactored out.
 */
@NgModule({
  exports: [OcfNoticesModule, TranslateModule, MaterialDesignModule, PipesModule],
})
export class OCFCommonModule {}
