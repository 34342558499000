export type EnvironmentPlatform = 'BusinessCentralSaaS' | 'BusinessCentralSelfProvisioning';
export type EnvironmentApplicationFamily = 'BusinessCentral';
export const ENVIRONMENT_TYPES = ['Sandbox', 'Production'] as const;
export type EnvironmentType = (typeof ENVIRONMENT_TYPES)[number];

export type EnvironmentsQueryParams = {
  coreCustomerId?: string;
  onlyActive?: boolean;
  applicationId?: string;
};

export const enum EnvironmentsToggleOptions {
  Active = 'Active',
  All = 'All',
}

export type EnvironmentBase = {
  id: string;
  coreCustomerId: string;
  lastUpdatedFromApiDateTime: string;
  customerName: string;
  name: string;
  platform: EnvironmentPlatform;
  type: EnvironmentType;
  state: string;
  platformVersion: string;
  applicationVersion: string;
  upcomingUpdate: string | null;
  availableAppUpdates: number;
  licensedUsers: number;
  usedStorage: number;
  availableStorage: number;
  webClientLoginUrl: string;
  adminCenterUrl: string;
  didTenantSelectDate: boolean;
  createdBy: string;
  createdOn: string;
};

export type EnvironmentListItemResponse = EnvironmentBase & {
  installedApplicationsCount: number;
  installedVersion: string | null;
};

export type EnvironmentResponse = EnvironmentBase & {
  tenantId: string;
  region: string;
  availableUpdateVersion: string;
  updateWindowStartTimeUtc: string;
  updateWindowEndTimeUtc: string;
  appSourceAppsUpdateCadence: string;
  ignoreUpgradeWindow: boolean;
  actionLogId: string;
  lastJobData: LongJobData;
  canTenantSelectDate: boolean;
  earliestSelectableUpgradeDate: string | null;
  latestSelectableUpgradeDate: string | null;
  sourceEnvironmentData: {
    name: string;
    type: EnvironmentType;
  };
};

export type Environment = EnvironmentResponse;

export type EnvironmentListItem = EnvironmentListItemResponse;

export type SetUpdateDateRequest = {
  runOn: string;
  ignoreUpgradeWindow: boolean;
};

export type SetUpdateWindowRequest = {
  preferredStartTimeUtc: string;
  preferredEndTimeUtc: string;
};

export type ActionQueuedResponse = {
  message: string;
  submittedAt: string;
};

export type EnvironmentsRefreshRequest = {
  tenantId: string;
};

export type LongJobData = {
  type: string;
  status: string;
  reason: string;
  date: string;
};

export type CreateEnvironmentRequest = {
  customerTenantId: string;
  environmentName: string;
  environmentType: EnvironmentType;
  countryCode: string;
  applicationFamily: EnvironmentApplicationFamily;
};

export type CopyEnvironmentRequest = {
  environmentName: string;
  environmentType: string;
};
