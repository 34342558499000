<cui-card
  *ngIf="{
    isLoading: (isLoading$ | async)!,
    customerChoices: (customerChoices$ | async)!
  } as obs$"
  [isLoading]="obs$.isLoading"
>
  <cui-card-header [showCloseBtn]="true" (close)="onCancel()">
    <h2>Add new {{ currentUrl.includes('requests') ? 'request' : 'assessment' }}</h2>
  </cui-card-header>
  <cui-card-body>
    <form [formGroup]="form" *ngIf="form">
      <cui-search-select
        formControlName="customerId"
        [placeholder]="'Search or select customer'"
        [items]="obs$.customerChoices"
        [required]="true"
        [clearable]="true"
      ></cui-search-select>
    </form>
    <div *ngIf="showCustomerForm">
      <hr />
      <h3>New customer</h3>
      <ocf-customer-form-base [isLoading]="obs$.isLoading" [form]="customerForm"></ocf-customer-form-base>
    </div>
  </cui-card-body>
  <cui-card-footer>
    <cui-button (clicked)="onCancel()">Cancel</cui-button>
    <cui-button-cta
      [disabled]="
        showCustomerForm
          ? customerForm && (customerForm.disabled || !customerForm.valid || !customerForm.dirty)
          : form && (form.disabled || !form.valid || !form.dirty)
      "
      (clicked)="onSave(form, customerForm)"
      >Create</cui-button-cta
    >
  </cui-card-footer>
</cui-card>
