import { createSelector, MemoizedSelector } from '@ngrx/store';
import { AppState, getRootState } from '../app.store';
import { AssessmentActions } from '../mm-program/assessment/assessment.model';

const selectModuleState = createSelector(getRootState, rootState => rootState.requests);

export const selectIsLoading = createSelector(selectModuleState, state => state.isLoading);
export const selectRequests = createSelector(selectModuleState, state => state.requests);

export const selectSelectedRequest = createSelector(selectModuleState, state => state.selectedRequest);
export const selectSelectedRequestId = createSelector(selectModuleState, state => state.selectedRequest && state.selectedRequest.id);
export const selectSelectedRequestLoading = createSelector(selectModuleState, state => state.selectedRequestLoading);

export const selectSelectedExternalProductId = createSelector(
  selectModuleState,
  state => state.selectedRequest && state.selectedRequest.product && state.selectedRequest.product.externalId,
);

export const selectSelectedAssessment = createSelector(selectModuleState, state => state.selectedAssessment);
export const selectSelectedAssessmentLoading = createSelector(selectModuleState, state => state.selectedAssessmentLoading);
export const selectAssessmentSaveCompleted = createSelector(selectModuleState, state => state.assessmentSaveCompleted);
export const selectSelectedAssessmentActions = createSelector(selectModuleState, state => state.selectedAssessment?.actions ?? []);
export const selectHasAction = (
  requiredAction: AssessmentActions,
): MemoizedSelector<AppState, boolean, (s1: AssessmentActions[]) => boolean> =>
  createSelector(selectSelectedAssessmentActions, actions => actions.includes(requiredAction));

export const selectSelectedRequestDescription = createSelector(selectModuleState, state => state.selectedProductDescription);
export const selectSelectedRequestDescriptionLoading = createSelector(selectModuleState, state => state.selectedProductDescriptionLoading);
